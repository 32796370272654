// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-case-stories-js": () => import("./../../../src/pages/case-stories.js" /* webpackChunkName: "component---src-pages-case-stories-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-model-behavioural-business-1-js": () => import("./../../../src/pages/our-model/behavioural-business-1.js" /* webpackChunkName: "component---src-pages-our-model-behavioural-business-1-js" */),
  "component---src-pages-our-model-the-science-of-coding-js": () => import("./../../../src/pages/our-model/the-science-of-coding.js" /* webpackChunkName: "component---src-pages-our-model-the-science-of-coding-js" */),
  "component---src-pages-our-models-js": () => import("./../../../src/pages/our-models.js" /* webpackChunkName: "component---src-pages-our-models-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-publications-articles-white-papers-js": () => import("./../../../src/pages/publications/articles-white-papers.js" /* webpackChunkName: "component---src-pages-publications-articles-white-papers-js" */),
  "component---src-pages-publications-books-js": () => import("./../../../src/pages/publications/books.js" /* webpackChunkName: "component---src-pages-publications-books-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-publications-presentations-js": () => import("./../../../src/pages/publications/presentations.js" /* webpackChunkName: "component---src-pages-publications-presentations-js" */),
  "component---src-pages-test-yourself-are-you-biased-js": () => import("./../../../src/pages/test-yourself/are-you-biased.js" /* webpackChunkName: "component---src-pages-test-yourself-are-you-biased-js" */),
  "component---src-pages-test-yourself-find-your-companys-behavioural-type-js": () => import("./../../../src/pages/test-yourself/find-your-companys-behavioural-type.js" /* webpackChunkName: "component---src-pages-test-yourself-find-your-companys-behavioural-type-js" */),
  "component---src-pages-test-yourself-js": () => import("./../../../src/pages/test-yourself.js" /* webpackChunkName: "component---src-pages-test-yourself-js" */),
  "component---src-pages-test-yourself-test-your-cultural-awareness-js": () => import("./../../../src/pages/test-yourself/test-your-cultural-awareness.js" /* webpackChunkName: "component---src-pages-test-yourself-test-your-cultural-awareness-js" */),
  "component---src-templates-our-models-js": () => import("./../../../src/templates/our-models.js" /* webpackChunkName: "component---src-templates-our-models-js" */)
}

